import React, { FC, FocusEventHandler, useEffect, useRef, useState } from 'react';
import { InputText, InputTextProps } from "primereact/inputtext";
import { ObjectUtils } from "primereact/utils";
import cn from "classnames";
import styles from "./Common.module.scss";
import { useDataContext } from "../../data/DataContext";
import { observer } from "mobx-react-lite";
import { InputNumber } from "primereact/inputnumber";

interface CustomTextInputProps extends InputTextProps {
    mode?: "decimal";
}

const defaultProps = {
    keyfilter: null,
    validateOnly: false,
    tooltip: null,
    tooltipOptions: null,
    onInput: null,
    onKeyPress: null,
    forwardRef: null
};

const CustomTextInput: FC<CustomTextInputProps> = (props) => {
    // PROPS
    const {
        prefix,
        placeholder,
        autoFocus,
        className,
        onChange,
        onFocus,
        onBlur,
        mode
    } = props;

    // USES
    const { themeBrightness } = useDataContext().stores.appStateStore.appState;

    // DATA
    const [ focus, setFocus ] = useState<boolean>(autoFocus ?? false);
    const inputProps = ObjectUtils.findDiffKeys(props, defaultProps);
    const inputRef = useRef<HTMLInputElement>(undefined);

    if (!prefix) {
        return mode === "decimal" ?
            (
                <InputNumber
                    {...inputProps}
                    placeholder={focus ? placeholder : undefined}
                    className={cn(className)}
                    mode="decimal"
                    autoFocus={autoFocus}
                    useGrouping={false}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    prefix={prefix}
                />
            )
            :
            (
                <InputText
                    {...inputProps}
                    onChange={onChange}
                    className={cn(className)}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    prefix={prefix}
                />
            );
    }

    // HANDLERS
    const inputOnFocusHandler: FocusEventHandler<HTMLInputElement> = (event: any) => {
        setFocus(true);
        onFocus && onFocus(event);
    }

    const inputOnBlurHandler: FocusEventHandler<HTMLInputElement> = (event: any) => {
        setFocus(false);
        onBlur && onBlur(event);
    }

    const divOnClickHandler = () => {
        if (!focus) {
            setFocus(true);
        }
    }

    // EFFECTS
    useEffect(() => {
        if (inputRef?.current && focus) {
            inputRef?.current.focus();
        }
    }, [ focus ]);

    return (
        <div className={styles.CustomTextInput}>
            <div
                className={cn(className,
                    "p-inputtext p-component p-filled",
                    styles.CustomTextInput__prefix, {
                        [styles.CustomTextInput__prefix_focus]: focus,
                        [styles.CustomTextInput__prefix_focus_dark]: focus && themeBrightness === "dark",
                        [styles.CustomTextInput__prefix_focus_light]: focus && themeBrightness === "light",
                        [styles.CustomTextInput__prefix_dark]: themeBrightness === "dark",
                        [styles.CustomTextInput__prefix_light]: themeBrightness === "light"
                    })}
                onClick={divOnClickHandler}
            >
                {prefix}
            </div>
            <InputText
                // @ts-ignore
                forwardRef={inputRef}
                {...inputProps}
                onChange={onChange}
                className={cn(className, styles.CustomTextInput__input, { [styles.CustomTextInput__input_prefix]: prefix })}
                autoFocus={focus}
                placeholder={placeholder}
                onFocus={inputOnFocusHandler}
                onBlur={inputOnBlurHandler}
                prefix={prefix}
            />
        </div>
    );
};

export default observer(CustomTextInput);
