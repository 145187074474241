import React, { Dispatch, DispatchWithoutAction, FC, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import styles from "./CallForPapers.module.scss";
import { Button } from "primereact/button";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import {
    instanceOfStepFirstModel,
    StepFirstModel,
    stepFirstModelDefaultValues
} from "../../data/srm/models/forms.models";
import { useDataContext } from "../../data/DataContext";
import { checkDontEdited, checkIfOnlyAutoFilledEdited } from "../../data/srm/forms.actions";
import { debounce } from "../../utils/common";

interface StepFormProps<T> {
    initData: T;
    submit: Dispatch<T>;
    save: Dispatch<T>;
    cancelCounter: number;
    actionIsLoading: boolean;
    back?: DispatchWithoutAction;
    render: (getFormErrorMessage, errors, control, getValues) => JSX.Element;
    submitTitleId?: string;
}

const StepForm: FC<StepFormProps<any>> = ({
                                              initData,
                                              actionIsLoading,
                                              submit, save, submitTitleId,
                                              cancelCounter,
                                              back,
                                              render
                                          }) => {
    const { localeAs } = useI18nLocaleFormat();
    const { authStore } = useDataContext().stores;
    const { isLogged, nameAndSurname, userEmail } = authStore;

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        reset
    } = useForm({
        mode: "onChange",
        defaultValues: initData
    });

    const dirtyFields = useWatch({
        control, defaultValue: initData
    });

    const onSubmit = (data: any) => {
        submit(data);
    };

    const getFormErrorMessage = name => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    // when change log mode -> try to fill
    useEffect(() => {
        if (isLogged) {
            const currentValues: StepFirstModel = getValues();

            if (!instanceOfStepFirstModel(currentValues)) {
                return;
            }

            if ((currentValues.firstName === undefined || currentValues.firstName === stepFirstModelDefaultValues.firstName) && nameAndSurname?.firstName) {
                setValue("firstName", nameAndSurname.firstName);
            }
            if ((currentValues.lastName === undefined || currentValues.lastName === stepFirstModelDefaultValues.lastName) && nameAndSurname?.lastName) {
                setValue("lastName", nameAndSurname.lastName);
            }
            if ((currentValues.email === undefined || currentValues.email === stepFirstModelDefaultValues.email) && userEmail) {
                setValue("email", userEmail);
            }
        }
    }, [ isLogged, initData ]);

    const debounceSave = useCallback(debounce(save, 400), []);

    // when user edit any field
    useEffect(() => {
        // exclude autofill fields
        if (checkIfOnlyAutoFilledEdited(dirtyFields, authStore)) {
            return;
        }
        // exclude dontchanged field
        if (checkDontEdited(dirtyFields, initData)) {
            return;
        }

        debounceSave(dirtyFields);
    }, [ dirtyFields ]);

    useEffect(() => {
        if (cancelCounter) {
            reset();
        }
    }, [ cancelCounter ]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.callForPapers__form}>
                <div className={cn("p-fluid", styles.callForPapers__fields)}>
                    {
                        render(getFormErrorMessage, errors, control, getValues)
                    }
                </div>
                <div className="p-pt-2 p-d-flex p-flex-column p-flex-sm-row-reverse p-jc-sm-between">
                    <Button type="submit"
                            label={localeAs(submitTitleId ?? "form.submit" as any)}
                            className="p-mt-2 p-button-raised"
                            loading={actionIsLoading}
                    />

                    {
                        back && <Button type="reset"
                                        label={localeAs("form.back")}
                                        className="p-mt-2 p-button-raised"
                                        onClick={back}
                        />
                    }
                </div>
            </form>
        </>
    );
};

export default observer(StepForm);
