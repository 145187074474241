import React, { FC } from 'react';
import { Controller } from "react-hook-form";
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import { Checkbox } from "primereact/checkbox";
import cn from "classnames";

interface FormCheckFieldProps {
    fieldName: string;
    fieldNameId?: string;
    fieldNameText?: JSX.Element;
    rulesTextId?: string;
    getFormErrorMessage: (name) => JSX.Element;
    errors: any;
    control: any;
    required?: boolean;
}

const FormCheckField: FC<FormCheckFieldProps> = ({
                                                     fieldName, fieldNameId, fieldNameText,
                                                     control, errors, required
                                                 }) => {
    const { localeAs } = useI18nLocaleFormat();

    return (
        <div className="p-field-checkbox">
            <Controller name={fieldName} control={control} rules={{ required: required ?? true }}
                        render={({ field, fieldState }) => (
                            <>
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)}
                                          checked={field.value}
                                          className={cn({ 'p-invalid': fieldState.invalid })}/>
                                <label htmlFor={fieldName} className={cn({ 'p-error': errors[fieldName] })}
                                >{fieldNameId ? localeAs(fieldNameId as any) : fieldNameText}</label>
                            </>
                        )}/>
        </div>
    );
};

export default FormCheckField;
