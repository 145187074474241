import { NotificationsStore } from "./notifications.store";
import * as Sentry from "@sentry/nextjs";

export const handleError = (notificationStore: NotificationsStore, error: Error | string, title?: string, timeToLiveinMin?: number) => {
    if (error instanceof Error) {
        notificationStore.addError((error as Error).message, title, timeToLiveinMin);
    } else {
        notificationStore.addError(error as string, title, timeToLiveinMin);
    }

    Sentry.captureException(error, notificationStore?.authStore ? {
        user: {
            id: notificationStore.authStore.userId?.toString(),
            username: notificationStore.authStore.displayName,
            email: notificationStore.authStore.userEmail
        }
    } : undefined);
}

export const handleSucessuffly = (notificationStore: NotificationsStore, message: string, title: string, timeToLiveInMin?: number) => {
    notificationStore.addInfoNote(message, title, timeToLiveInMin);
}

export const handleWarning = (notificationStore: NotificationsStore, message: string, title: string, timeToLiveInMin?: number) => {
    notificationStore.addWarnNote(message, title, timeToLiveInMin);
}

export const turnOffGlobalSpinner = (notificationStore: NotificationsStore, delay?: number) => {
    setTimeout(() => notificationStore.turnOffGlobalIsLoading(), delay ?? 500);
}

export const turnOnGlobalSpinner = (notificationStore: NotificationsStore) => {
    notificationStore.turnOnGlobalIsLoading();
}

