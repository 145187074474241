import React, { FC, useCallback, useEffect, useState } from 'react';
import { Steps } from 'primereact/steps';
import styles from "./CallForPapers.module.scss";
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import StepCard from "./StepCard";
import { CfpEvent } from "../../data/srm/models/events.models";
import { useIsBrowser } from "../../hooks/useIsBrowser";
import { useDataContext } from "../../data/DataContext";
import { Draft, DraftScope } from "../../data/srm/models/draft.models";
import { StepFirstModel, StepSecondModel, StepThirdModel } from "../../data/srm/models/forms.models";
import {
    mergeDraftWithStepFirst,
    mergeDraftWithStepSecond,
    mergeDraftWithStepThird
} from "../../data/srm/draft.actions";
import { handleError } from "../../data/notifications/notifications.actions";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";
import { useRouter } from "next/router";
import { replaceEmailInText, replaceEventNameInText, stageValidate } from "../../data/srm/forms.actions";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { removeUndefined } from "../../utils/common";
import { Lang } from "../../data/common/common.models";

interface CallForPapersMainProps {
    cfpEvent: CfpEvent;
    step?: number;
    pushStageToRouter: (router, stage: number) => void;
    scope: DraftScope;
}

const CallForPapersController: FC<CallForPapersMainProps> = ({
                                                                 step,
                                                                 cfpEvent,
                                                                 pushStageToRouter,
                                                                 scope
                                                             }) => {
    // DEPS
    const { localeAs, localeFrom } = useI18nLocaleFormat();
    const isBrowser = useIsBrowser();
    const router = useRouter();
    const { srmService, authService } = useDataContext().services;
    const { notificationsStore, srmStore } = useDataContext().stores;
    const {
        currentDraft,
        currentDraftIsLoaded,
        currentDraftIsNotLoaded,
        finalScreenModel,
        currentDraftIsEmpty
    } = srmStore;

    // STATES
    const [ actionIsLoading, setActionIsLoading ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const isPartnerFlag = scope === "PARTNER";

    // COMPUTED
    const stepsItems = [
        { label: localeAs("form.step1") },
        { label: localeAs("form.step2") },
        { label: localeAs("form.step3") },
    ];

    const locale: Lang = (router.locale ?? "ru") as Lang;

    // GENERALLY HANDLERS
    const errorHandler = (error?: Error) => handleError(notificationsStore, `${localeAs("notifications.error.getDraft")}${error ? " - " + error.message : ""}`, localeAs("notifications.error.title"));
    const finallyHandler = () => {
        setTimeout(() => setIsLoading(false), 500)
    };

    // EFFECTS
    // main useEffect
    useEffect(() => {
        if (currentDraftIsNotLoaded) {
            setIsLoading(true);
            // load all drafts for user or cookie
            srmService.loadLastDraft(cfpEvent.project, cfpEvent.version)
                .then(stage => {
                    pushStageToRouter(router, stage);
                })
                .then(() => authService.updateDataFromJpa(true))
                // if exist one draft - use it
                .catch(errorHandler)
                .finally(finallyHandler);
        }
    }, [ currentDraft ]);

    // reset CFP when change cfpEvent
    useEffect(() => {
        srmService.reset();
    }, [ cfpEvent.project, cfpEvent.version ]);

    // HANDLERS
    const putEditedDraft = (draft: Draft, isSilent: boolean = false): Promise<boolean> => {
        !isSilent && setActionIsLoading(true);

        return srmService.putEditedDraft(draft, locale, scope)
            .catch(() => {
                !isSilent && errorHandler();
                return false;
            });
    };

    const postFinalDraft = (newDraft: Draft): Promise<boolean> => {
        setActionIsLoading(true);

        return srmService.postFinalDraft(newDraft, locale, scope)
            .catch(() => {
                errorHandler();
                return false;
            });
    }

    const updateStepAndLoader = (result: boolean, nextStage: number) => {
        return () => {
            setActionIsLoading(false);
            if (result) {
                pushStageToRouter(router, nextStage);
            }
        };
    };

    const genericSubmitHandler = (newDraft: Draft, nextStep: number, action: (d: Draft) => Promise<boolean>) => {
        let callback: () => void;

        action(newDraft)
            .then(result => {
                callback = updateStepAndLoader(result, nextStep);
            })
            .finally(() => {
                setTimeout(callback, 500);
            });
    }

    const submitHandlers = [
        (data: StepFirstModel) => {
            const newDraft = mergeDraftWithStepFirst(currentDraft, data);
            genericSubmitHandler(newDraft, 1, putEditedDraft);
        },
        (data: StepSecondModel) => {
            const newDraft = mergeDraftWithStepSecond(currentDraft, data);
            genericSubmitHandler(newDraft, 2, putEditedDraft);
        },
        (data: StepThirdModel) => {
            const newDraft = mergeDraftWithStepThird(currentDraft, data);
            genericSubmitHandler(newDraft, 100, postFinalDraft);
        }
    ];

    const genericSaveHandler = useCallback((draft: Draft) => {
        // TODO catch error and save to LS
        return putEditedDraft(draft, true);
    }, [ locale ]);

    const saveHandlers = [
        (data: StepFirstModel) => {
            const newDraft = removeUndefined({ ...mergeDraftWithStepFirst(currentDraft, data), stage: undefined });
            return genericSaveHandler(newDraft);
        },
        (data: StepSecondModel) => {
            const newDraft = removeUndefined({ ...mergeDraftWithStepSecond(currentDraft, data), stage: undefined });
            return genericSaveHandler(newDraft);
        },
        (data: StepThirdModel) => {
            const newDraft = removeUndefined({ ...mergeDraftWithStepThird(currentDraft, data), stage: undefined });
            return genericSaveHandler(newDraft);
        }
    ];

    const backToStepHandler = (stage: number) => {
        if (!stageValidate(stage)) {
            return;
        }

        let callback: () => void;

        return putEditedDraft({ id: currentDraft.id, stage })
            .then(result => {
                callback = updateStepAndLoader(result, stage);
            })
            .finally(() => {
                setTimeout(callback, 500);
            });
    }

    const backToBeginHandler = (needReset: boolean = true, needToCleanFinalData: boolean = true) => {
        // put data to init values
        needReset && srmService.reset(needToCleanFinalData);
        pushStageToRouter(router, 0);
    }

    const cancelHandler = (): Promise<boolean> => {
        setActionIsLoading(true);

        const callback = () => {
            setActionIsLoading(false);
            backToBeginHandler(false, true);
        };

        return srmService.cancelDraft()
            .then(() => {
                setTimeout(callback, 500);
                return true;
            })
            .catch(() => {
                handleError(notificationsStore, localeAs("notifications.error.saveDraft"), localeAs("notifications.error.title"));
                setTimeout(callback, 500);
                return false;
            });
    };

    // RENDERS
    if (step === undefined || !isBrowser) {
        return null;
    }

    const goToCallForPapepsHome = () => {
        router.push(`https://jugru.org/${locale === 'ru' ? '' : 'en/'}callforpapers-speaker/`).then();
    }

    const renderBody = () => {
        if (!isLoading) {
            if (currentDraftIsLoaded && step !== 100) {
                return (
                    <>
                        <Steps model={stepsItems}
                               activeIndex={step > 2 ? 2 : step}
                               readOnly={true}
                               className={styles.callForPapers__steps}
                        />
                        <StepCard
                            step={step}
                            eventName={localeFrom(cfpEvent.event.name)}
                            eventId={cfpEvent.event.id}
                            submitHandlers={submitHandlers}
                            saveHandlers={saveHandlers}
                            cancelHandler={cancelHandler}
                            backHandler={backToStepHandler}
                            actionIsLoading={actionIsLoading}
                            scope={scope}
                        />
                    </>
                );
            }
            if (currentDraftIsEmpty && step === 100) {
                return (
                    <>
                        <Steps model={stepsItems}
                               activeIndex={2}
                               readOnly={true}
                               className={styles.callForPapers__steps}
                        />
                        <Card
                            className={cn(styles.callForPapers__card, { [styles.callForPapers__card_opacity]: actionIsLoading })}
                            title={localeAs("form.success")}
                            footer={(
                                <>
                                    <Button label={localeAs("form.success.toBegin")} icon="pi pi-home"
                                            className="p-button-secondary"
                                            onClick={() => goToCallForPapepsHome()}/>
                                    <Button label={localeAs("form.success.again")} icon="pi pi-replay"
                                            className="p-button-primary p-ml-2"
                                            onClick={() => backToBeginHandler(true, false)}/>
                                </>
                            )}
                        >
                            <p style={{ lineHeight: '1.5' }}>{replaceEventNameInText(localeAs("form.success.text1"), localeFrom(cfpEvent.event.name))}</p>
                            <p className="p-mt-2"
                               style={{ lineHeight: '1.5' }}
                               dangerouslySetInnerHTML={
                                   {
                                       __html:
                                           replaceEmailInText(
                                               scope === "MEETUP" ?
                                                   localeAs("form.success.text2.meetup") :
                                                   scope === "PARTNER" ?
                                                       localeAs("form.success.text2.partner") :
                                                       localeAs("form.success.text2"),
                                               finalScreenModel?.email ?? "", scope)
                                   }
                               }
                            />
                        </Card>
                    </>
                );
            }
        }
        if (isLoading) {
            return (
                <div className="p-d-flex p-jc-center p-ac-center p-mt-6 p-pt-6">
                    <ProgressSpinner/>
                </div>
            );
        }
    }

    return (
        <div className={styles.callForPapers__main}>
            <div className={cn(styles.callForPapers__header, "p-mb-5")}>
                <h4>{
                    isPartnerFlag ?
                        localeAs("form.header.partner") : localeAs("form.header")} {localeFrom(cfpEvent.event.name)}</h4>
            </div>
            {
                renderBody()
            }
        </div>
    );
};

export default observer(CallForPapersController);
