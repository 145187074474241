import React, { FC } from 'react';
import { Controller } from "react-hook-form";
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import { RadioButton } from "primereact/radiobutton";
import cn from "classnames";

interface FormRadioGroupFieldProps {
    className?: string;
    fieldName: string;
    fieldParams: { key: string, displayId: string }[];
    fieldNameId?: string;
    fieldNameText?: JSX.Element;
    control: any;
    autoFocus?: boolean;
    required?: boolean;
}

const FormRadioGroupField: FC<FormRadioGroupFieldProps> = ({
                                                               className,
                                                               fieldName, fieldNameId, fieldParams, fieldNameText,
                                                               control, required
                                                           }) => {
    const { localeAs } = useI18nLocaleFormat();

    const rules = {
        required: required === true
    };

    const renderRadioGroup = (field, fieldState) => {
        return fieldParams.map((value, index) => {
            const inputId = `${field.name}_${index}`;
            return (
                <div className="p-field-radiobutton" key={`div_${inputId}`}>
                    <RadioButton
                        key={inputId}
                        inputId={inputId}
                        name={field.name}
                        value={localeAs(value.displayId as any)}
                        onChange={() => field.onChange(value.key)}
                        checked={field.value === value.key}
                        className={cn({ 'p-invalid': fieldState?.invalid })}
                    />
                    <label htmlFor={inputId}>{localeAs(value.displayId as any)}</label>
                </div>
            );
        });
    }

    return (
        <div className={cn("p-field", className)}>
            <Controller name={fieldName} control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <>
                                {
                                    fieldNameText
                                }
                                {
                                    fieldNameId &&
                                    <label htmlFor={fieldName}
                                           className="p-mb-4"
                                    >{localeAs(fieldNameId as any)}</label>
                                }
                                {
                                    renderRadioGroup(field, fieldState)
                                }
                            </>
                        )}/>
        </div>
    );
};

export default FormRadioGroupField;
