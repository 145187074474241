import React, { FC } from "react";
import cn from "classnames";
import styles from "./Error000Main.module.scss"
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import { useRouter } from "next/router";
import { replaceEventNameInText } from "../../../data/srm/forms.actions";
import { CfpEvent } from "../../../data/srm/models/events.models";

interface ErrorUnknownProps {
    cfpEvent?: CfpEvent;
}

export const ErrorUnknown: FC<ErrorUnknownProps> = ({ cfpEvent, children }) => {
    const { localeAs, localeFrom } = useI18nLocaleFormat();
    const router = useRouter();

    return (
        <div className={styles.errorMainContainer}>
            <Card
                className={cn(styles.errorMainContainer__card)}
                title={replaceEventNameInText(localeAs("form.disabled"), localeFrom(cfpEvent?.event.name))}
                footer={(
                    <Button label={localeAs("form.success.toBegin")}
                            icon="pi pi-home"
                            className="p-button-secondary"
                            onClick={() => router.push("/")}/>
                )}
            >
                {children}
            </Card>
        </div>
    );
}
