import React, { FC, useState } from 'react';
import { Controller } from "react-hook-form";
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import cn from "classnames";
import styles from "../CallForPapers/CallForPapers.module.scss";
import stylesMain from "./Common.module.scss";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import CustomTextInput from "./CustomTextInput";
import { useDataContext } from "../../data/DataContext";

interface FormTextFieldProps {
    className?: string;
    fieldName: string;
    fieldNameId: string;
    getFormErrorMessage: (name: any) => JSX.Element;
    errors: any;
    control: any;
    required?: boolean;
    requiredRulesTextId?: string;
    pattern?: RegExp;
    patternRulesTextId?: string;
    isPhoneNumber?: boolean;
    isTextArea?: boolean;
    rows?: number;
    cols?: number;
    autoFocus?: boolean;
    notes?: string;
    subNotes?: string;
    subNotesDanger?: boolean;
    fieldTitleId?: string;
    isTaggedField?: boolean;
    placeholder?: string;
    prefix?: string;
    deletedRexExp?: RegExp | string;
    maxLength?: number;
}

const validate = (value: string) => {
    if (value.trim().length === 0) {
        return false;
    }
    return true;
}

const FormTextField: FC<FormTextFieldProps> = (props) => {
    // PROPS
    const {
        className,
        fieldName, fieldNameId,
        control, getFormErrorMessage, errors,
        required, pattern, requiredRulesTextId, patternRulesTextId,
        isPhoneNumber, autoFocus,
        isTextArea, rows = 5, cols = 30, isTaggedField,
        notes, fieldTitleId, subNotes, subNotesDanger,
        placeholder, prefix, deletedRexExp,
        maxLength
    } = props;

    // DEPS
    const { localeAs } = useI18nLocaleFormat();
    const { themeBrightness } = useDataContext().stores?.appStateStore?.appState

    // DATA
    const fieldNameText = localeAs(fieldNameId as any);

    const getRules = () => {
        let rules = {};
        if ((required === undefined || required === true) && requiredRulesTextId) {
            rules = {
                ...rules,
                required: localeAs(requiredRulesTextId as any),
                validate: {
                    required: validate,
                }
            }
        }
        if ((required === undefined || required === true) && !requiredRulesTextId) {
            rules = {
                ...rules,
                required: true,
                validate: {
                    required: validate,
                }
            }
        }
        if (pattern) {
            rules = {
                ...rules,
                pattern: {
                    value: pattern,
                    message: localeAs(patternRulesTextId as any)
                }
            }
        }
        if (maxLength) {
            rules = {
                ...rules,
                maxLength
            }
        }

        return rules;
    }

    const [ focus, setFocus ] = useState<boolean>(autoFocus ?? false);

    // RENDERS
    const renderMaxLenBlock = (value?: string, short?: boolean) => {
        if (!maxLength) {
            return null;
        }

        const left = maxLength - (value ?? "").length;
        const leftNumber = left > 0 ? left : 0;

        const leftText = short ?
            `${leftNumber.toString()} / ${maxLength.toString()}`
            : localeAs("form.textarea.symbols.left")
                .replace("NN", leftNumber.toString())
                .replace("KK", maxLength.toString());

        return (
            <div
                className={cn({
                    ["p-error"]: leftNumber === 0,
                    [stylesMain.FormTextField__maxLen_label_text]: leftNumber > 0,
                }, stylesMain.FormTextField__maxLen_label)}>{leftText}</div>
        );
    };

    const renderField = () => {
        if (isPhoneNumber) {
            return ({ field, fieldState }) => (
                <CustomTextInput id={field.name}
                                 {...field}
                                 onChange={e => field.onChange(getFieldValueWithoutSymbols(e.target.value, /\D+/g))}
                                 mode="decimal"
                                 className={cn({ "p-invalid": fieldState.invalid })}
                                 autoFocus={autoFocus}
                                 prefix={prefix}
                                 placeholder={placeholder}
                                 onFocus={() => setFocus(true)}
                                 onBlur={() => setFocus(false)}
                />
            );
        }
        if (isTextArea) {
            return ({ field, fieldState }) => (
                <>
                    <InputTextarea id={field.name}
                                   rows={rows}
                                   cols={cols}
                                   {...field}
                                   autoResize
                                   className={cn({ 'p-invalid': fieldState.invalid })}
                                   autoFocus={autoFocus}/>
                    {
                        renderMaxLenBlock(field.value, fieldNameText.length > 40)
                    }
                </>
            );
        }
        if (isTaggedField) {
            return ({ field, fieldState }) => (
                <Chips id={field.name}
                       {...field}
                       className={cn({ 'p-invalid': fieldState.invalid })}
                       allowDuplicate={false}
                       autoFocus={autoFocus}/>
            )
        }

        return ({ field, fieldState }) => (
            <>
                <CustomTextInput id={field.name}
                                 {...field}
                                 onChange={deletedRexExp ? e => field.onChange(getFieldValueWithoutSymbols(e.target.value, deletedRexExp)) : field.onChange}
                                 className={cn({ 'p-invalid': fieldState.invalid })}
                                 autoFocus={autoFocus}
                                 placeholder={placeholder}
                                 prefix={prefix}
                                 onFocus={() => setFocus(true)}
                                 onBlur={() => setFocus(false)}/>
                {
                    renderMaxLenBlock(field.value, fieldNameText.length > 40)
                }
            </>
        );
    }

    const getFieldValueWithoutSymbols = (v: string, symbols: string | RegExp) => v.replaceAll(symbols, "");

    return (
        <div className={cn("p-field", className)}>
            {
                fieldTitleId &&
                <p className="p-mb-4">{localeAs(fieldTitleId as any)}</p>
            }
            <div className={cn("p-inputgroup", stylesMain.FormTextField)}>
                <span className={cn("p-float-label", {
                    [stylesMain.FormTextField__label_prefix]: prefix,
                    [stylesMain.FormTextField__label_prefix_focus]: prefix && (focus || control._formValues[fieldName])
                })}>
                    <Controller name={fieldName}
                                control={control}
                                rules={getRules()}
                                render={renderField()}/>
                    <label htmlFor={fieldName}
                           className={cn({ 'p-error': errors[fieldName] })}>
                      {
                          fieldNameText
                      }&nbsp;
                        {
                            (required === undefined || required) &&
                            <span
                                className={cn(styles.callForPapers__form_label_span, { 'p-d-none': errors[fieldName] && requiredRulesTextId })}>
                                {
                                    localeAs("form.required")
                                }
                            </span>
                        }
                    </label>
                </span>
            </div>
            {
                notes &&
                <div className={cn("p-mt-2", stylesMain.FormTextField__subNotes)}
                     dangerouslySetInnerHTML={{ __html: notes }}
                />
            }
            {
                subNotes && <div className={cn("p-mt-2", stylesMain.FormTextField__subNotes, {
                    [stylesMain.FormTextField__subNotes_dark]: subNotesDanger && themeBrightness === "dark",
                    [stylesMain.FormTextField__subNotes_light]: subNotesDanger && themeBrightness === "light",
                })}
                                 dangerouslySetInnerHTML={{ __html: subNotes }}
                />
            }
            {
                getFormErrorMessage(fieldName)
            }
        </div>
    );
};

export default FormTextField;
