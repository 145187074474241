import React, { Dispatch, FC, useState } from 'react';
import { Card } from "primereact/card";
import styles from "./CallForPapers.module.scss";
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import { useIsBrowser } from "../../hooks/useIsBrowser";
import { useDataContext } from "../../data/DataContext";
import { publicEndpointLogin } from "../../data/auth/auth.api.service";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import {
    extractStepFirstModelFromDraft,
    extractStepSecondModelFromDraft,
    extractStepThirdModelFromDraft
} from "../../data/srm/forms.actions";
import StepForm from "./StepForm";
import FormTextField from "../Common/FormTextField";
import FormRadioGroupField from "../Common/FormRadioGroupField";
import {
    ActivityFormatRadioValues,
    ActivityTypeRadioValues,
    isEventOffline,
    StepFirstModel,
    StepSecondModel,
    WhenceTypeRadioValues
} from "../../data/srm/models/forms.models";
import FormCheckField from "../Common/FormCheckField";
import { useWatch } from "react-hook-form";
import { Button } from "primereact/button";
import Tippy from "@tippyjs/react";
import { Message } from "primereact/message";
import { DraftScope } from "../../data/srm/models/draft.models";

interface StepCardProps {
    step: number;
    eventName: string;
    eventId: number;
    submitHandlers: Dispatch<any>[];
    saveHandlers: Dispatch<any>[];
    cancelHandler: () => Promise<boolean>;
    backHandler: Dispatch<number>;
    actionIsLoading: boolean;
    scope: DraftScope;
}

const StepCard: FC<StepCardProps> = ({
                                         step,
                                         // eventName,
                                         eventId,
                                         submitHandlers, saveHandlers,
                                         actionIsLoading,
                                         cancelHandler,
                                         backHandler,
                                         scope
                                     }) => {
        const isBrowser = useIsBrowser();
        const { authStore, srmStore, appStateStore } = useDataContext().stores;
        const { localeAs } = useI18nLocaleFormat();
        const { isLogged, lkUserData } = authStore;
        const { currentDraft, finalScreenModel } = srmStore;
        const { disableTips } = appStateStore.appState;

        // STATE
        const [ cancelCounter, setCancelCounter ] = useState(0);
        const isPartnerFlag = scope === "PARTNER";
        const isMeetupFlag = scope === "MEETUP";
        const isRegularFlag = scope === "REGULAR";

        // RENDERS
        const renderSubheader = () => {
            if (step === 0) {
                if (!isLogged) {
                    return (
                        <div><span>{localeAs("form.step1.subheader.nonauth")} </span>
                            <a href={publicEndpointLogin(window.location.href)}>
                                {localeAs("form.step1.texts.authorize_link")}
                            </a>
                        </div>
                    );
                } else {
                    return (
                        <div className="p-flex-column">
                            <div>{localeAs("form.step1.subheader.auth")} <span
                                className={cn("p-text-italic", styles.callForPapers__card_email)}>{authStore.userEmail}</span>
                            </div>
                            <div className="p-mt-2">{localeAs("form.step1.subheader.auth2")} </div>
                        </div>
                    );
                }
            }

            return null;
        }

        const cancelHandlerWithQ = () =>
            confirmDialog({
                message: localeAs("form.cancel.message"),
                header: localeAs("form.cancel.header"),
                icon: 'pi pi-exclamation-triangle',
                accept: () => cancelHandler().then(result => result && setCancelCounter(cancelCounter + 1)),
                acceptLabel: localeAs("form.cancel.ok"),
                rejectLabel: localeAs("form.cancel.cancel"),
            });

        const renderHeader = () =>
            (
                <div className={styles.callForPapers__card_header}>
                    <h4>{localeAs(`form.step${step + 1}.header` as any)}</h4>
                    {renderSubheader()}
                    {
                        currentDraft &&
                        <Tippy
                            content={localeAs("form.cancel")}
                            disabled={disableTips}
                        >
                            <Button
                                icon="pi pi-times"
                                className={cn("p-button-danger p-button-outlined", styles.callForPapers__card_cancelButton)}
                                onClick={cancelHandlerWithQ}
                            />
                        </Tippy>
                    }
                </div>
            );

        const renderStep1ConsentElement = () =>
            (
                <span dangerouslySetInnerHTML={{ __html: localeAs("form.step1.fields.acceptGDPR") }}/>
            );

        const renderStep3ConsentElement = () =>
            (
                <span dangerouslySetInnerHTML={{ __html: localeAs("form.step3.fields.acceptGDPR") }}/>
            );

        const renderStep3AdsConsentElement = () =>
            (
                <span
                    dangerouslySetInnerHTML={{ __html: localeAs("form.step3.fields.consents.adsAndSubscriptionsOptionalConsentAccepted") }}/>
            );

        const getFormBody = (getFormErrorMessage, errors, control, getValues) => {
            if (step === 0) {
                const currentValues: StepFirstModel = getValues();
                const whenceType: string = useWatch({
                    control,
                    name: "whenceType",
                    defaultValue: "DEVREL"
                });

                return (
                    <>
                        <FormTextField fieldName="firstName"
                                       fieldNameId="form.step1.fields.name"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       autoFocus
                        />
                        <FormTextField fieldName="lastName"
                                       fieldNameId="form.step1.fields.surname"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                        />
                        <FormTextField fieldName="company"
                                       fieldNameId="form.step1.fields.company"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                        />
                        <FormTextField fieldName="email"
                                       fieldNameId="form.step1.fields.email"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       pattern={/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i}
                                       patternRulesTextId="form.step1.fields.email.incorrect"
                                       subNotes={
                                           authStore?.isLogged && currentValues?.email?.toLowerCase() !== authStore?.userEmail?.toLowerCase()
                                               ? localeAs("form.step1.fields.email.subNotes.new")
                                               : localeAs("form.step1.fields.email.subNotes")
                                       }
                                       subNotesDanger={authStore?.isLogged && currentValues?.email?.toLowerCase() !== authStore?.userEmail?.toLowerCase()}
                        />
                        <FormTextField fieldName="phone"
                                       fieldNameId="form.step1.fields.phone"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       prefix="+"
                                       isPhoneNumber
                        />
                        <FormTextField fieldName="telegram"
                                       fieldNameId="form.step1.fields.telegram"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       required
                                       subNotes={localeAs("form.step1.fields.telegram.subNotes")}
                        />
                        <FormTextField fieldName="socialMedia"
                                       fieldNameId="form.step1.fields.socialMedia"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       required={false}
                        />
                        <FormTextField fieldName="biography"
                                       fieldNameId="form.step1.fields.biography"
                                       requiredRulesTextId="form.step1.fields.biography.required"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       isTextArea={true}
                                       className={cn(styles.fixed_field)}
                        />
                        {
                            isRegularFlag &&
                            <>
                                <FormRadioGroupField
                                    fieldName="whenceType"
                                    fieldParams={WhenceTypeRadioValues}
                                    fieldNameId="form.step1.fields.whence"
                                    control={control}
                                    autoFocus
                                    required
                                />
                                {
                                    whenceType === "CUSTOM" &&
                                    <FormTextField fieldName="whence"
                                                   fieldNameId="form.step1.fields.whence.CUSTOM"
                                                   getFormErrorMessage={getFormErrorMessage}
                                                   errors={errors}
                                                   control={control}
                                                   isTextArea={true}
                                                   rows={3}
                                                   className={cn(styles.fixed_field)}
                                                   maxLength={256}
                                    />
                                }
                            </>
                        }
                        <FormCheckField fieldName="acceptGDPR"
                                        fieldNameText={renderStep1ConsentElement()}
                                        getFormErrorMessage={getFormErrorMessage}
                                        errors={errors}
                                        control={control}
                        />
                    </>
                )
            }
            if (step === 1) {
                const typeValue: string = useWatch({
                    control,
                    name: "typeValue",
                    defaultValue: "TALK"
                });
                const format: string = useWatch({
                    control,
                    name: "format",
                    defaultValue: "ONLINE_REMOTE"
                });
                const currentValues: StepSecondModel = getValues();
                return (
                    <>
                        <FormRadioGroupField
                            fieldName="format"
                            fieldParams={ActivityFormatRadioValues(scope, isEventOffline(eventId))}
                            // disabled by https://wiki.jugru.org/pages/viewpage.action?pageId=563577358
                            // fieldNameText={
                            //     !isMeetupFlag ?
                            //         <div className="p-mb-4">
                            //             <p>{localeAs("form.step2.subheader.1").replace("NAME", eventName)} </p>
                            //             <p>{localeAs("form.step2.subheader.2")} </p>
                            //         </div> : undefined
                            // }
                            fieldNameId={isRegularFlag ? "form.step2.fields.format" : "form.step2.fields.format.partner"}
                            control={control}
                            autoFocus
                        />
                        {
                            !isMeetupFlag &&
                            (format === "ONLINE_STUDIO" || currentValues.format === "ONLINE_STUDIO") &&
                            <Message className="p-mb-4" severity="info"
                                     text={localeAs("form.step2.fields.format.ONLINE_STUDIO.note")}/>
                        }
                        {
                            isRegularFlag &&
                            (format === "UNKNOWN" || currentValues.format === "UNKNOWN") &&
                            <Message className="p-mb-4" severity="info"
                                     text={localeAs("form.step2.fields.format.UNKNOWN.note")}/>
                        }
                        {/*rm by SRM-876*/}
                        {/*{*/}
                        {/*    isRegularFlag &&*/}
                        {/*    (format === "OFFLINE" || currentValues.format === "OFFLINE") &&*/}
                        {/*    <Message className="p-mb-4" severity="info"*/}
                        {/*             text={localeAs("form.step2.fields.format.OFFLINE.note")}/>*/}
                        {/*}*/}
                        <FormRadioGroupField
                            fieldName="typeValue"
                            fieldNameId="form.step2.fields.typeValue"
                            fieldParams={ActivityTypeRadioValues(scope)}
                            control={control}
                            className="p-mb-4"
                        />
                        {
                            (typeValue === "CUSTOM" || currentValues.typeValue === "CUSTOM") &&
                            <FormTextField fieldName="typeDetails"
                                           fieldNameId="form.step2.fields.typeDetails"
                                           getFormErrorMessage={getFormErrorMessage}
                                           errors={errors}
                                           control={control}
                                           notes={localeAs("form.step2.fields.typeDetails.notes")}
                                // className="p-mt-0"
                            />
                        }
                        {/*rm by SRM-876*/}
                        {/*{*/}
                        {/*    (isRegularFlag || isMeetupFlag) &&*/}
                        {/*    <FormTextField fieldName="coSpeakerCandidates"*/}
                        {/*                   fieldNameId="form.step2.fields.coSpeakerCandidates"*/}
                        {/*                   fieldTitleId={*/}
                        {/*                       scope === "MEETUP" ?*/}
                        {/*                           "form.step2.fields.coSpeakerCandidates.title.meetup"*/}
                        {/*                           : "form.step2.fields.coSpeakerCandidates.title"*/}
                        {/*                   }*/}
                        {/*                   notes={localeAs("form.step2.fields.coSpeakerCandidates.subtitle")}*/}
                        {/*                   getFormErrorMessage={getFormErrorMessage}*/}
                        {/*                   errors={errors}*/}
                        {/*                   control={control}*/}
                        {/*                   required={false}*/}
                        {/*                   isTaggedField*/}
                        {/*    />*/}
                        {/*}*/}
                    </>
                );
            }
            if (step === 2) {
                return (
                    <>
                        <FormTextField fieldName="title"
                                       fieldNameId="form.step3.fields.title"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       notes={localeAs("form.step3.fields.title.notes")}
                                       autoFocus
                                       maxLength={100}
                        />
                        <FormTextField fieldName="description"
                                       fieldNameId="form.step3.fields.description"
                                       getFormErrorMessage={getFormErrorMessage}
                                       errors={errors}
                                       control={control}
                                       notes={
                                           scope === "MEETUP" ?
                                               localeAs("form.step3.fields.description.notes.meetup")
                                               : localeAs("form.step3.fields.description.notes")
                                       }
                                       isTextArea
                        />
                        {
                            isRegularFlag &&
                            <FormTextField fieldName="plan"
                                           fieldNameId="form.step3.plan.description"
                                           getFormErrorMessage={getFormErrorMessage}
                                           errors={errors}
                                           control={control}
                                           notes={localeAs("form.step3.fields.plan.notes")}
                                           isTextArea
                                           required={false}
                                           maxLength={10000}
                            />
                        }
                        {
                            isPartnerFlag &&
                            <div className="p-mb-4"
                                 style={{ fontSize: "0.8rem" }}>{localeAs("form.step3.fields.plan.notes.partner")}</div>
                        }
                        <FormCheckField fieldName="acceptGDPR"
                                        fieldNameText={renderStep3ConsentElement()}
                                        getFormErrorMessage={getFormErrorMessage}
                                        errors={errors}
                                        control={control}
                        />
                        {
                            !lkUserData?.adsAndSubscriptionsOptionalConsentAccepted &&
                            <FormCheckField fieldName="adsAndSubscriptionsOptionalConsentAccepted"
                                            fieldNameText={renderStep3AdsConsentElement()}
                                            getFormErrorMessage={getFormErrorMessage}
                                            errors={errors}
                                            control={control}
                                            required={false}
                            />
                        }
                    </>
                );
            }
        }

        if (!isBrowser) {
            return null;
        }

        return (
            <Card className={cn(styles.callForPapers__card, { [styles.callForPapers__card_opacity]: actionIsLoading })}
                  header={renderHeader}
            >
                <ConfirmDialog/>
                {
                    actionIsLoading &&
                    <div className={styles.callForPapers__spinner}>
                        <ProgressSpinner/>
                    </div>
                }
                {
                    step === 0 && <StepForm
                        initData={finalScreenModel ?? extractStepFirstModelFromDraft(currentDraft, lkUserData)}
                        submit={submitHandlers[0]}
                        save={saveHandlers[0]}
                        cancelCounter={cancelCounter}
                        actionIsLoading={actionIsLoading}
                        render={getFormBody}
                    />
                }
                {
                    step === 1 && <StepForm
                        initData={extractStepSecondModelFromDraft(currentDraft)}
                        submit={submitHandlers[1]}
                        save={saveHandlers[1]}
                        cancelCounter={cancelCounter}
                        actionIsLoading={actionIsLoading}
                        back={() => backHandler(0)}
                        render={getFormBody}
                    />
                }
                {
                    step === 2 && <StepForm
                        initData={extractStepThirdModelFromDraft(currentDraft, lkUserData)}
                        submit={submitHandlers[2]}
                        save={saveHandlers[2]}
                        cancelCounter={cancelCounter}
                        actionIsLoading={actionIsLoading}
                        back={() => backHandler(1)}
                        render={getFormBody}
                        submitTitleId="form.submitFinal"
                    />
                }
            </Card>
        );
    }
;

export default observer(StepCard);
