import React, { FC, useEffect, useState } from 'react';

import { GetServerSideProps } from 'next';
import Layout from "../components/Layout/Layout";
import { getEventVersionInfoFromUrl, getStageNumberFromHash } from "../utils/url-parser";
import CallForPapersController from "../components/CallForPapers/CallForPapersController";
import { SrmApiService } from "../data/srm/srm.api.service";
import { CfpEvent } from "../data/srm/models/events.models";
import { cfpIsClosed, cfpIsFuture, cfpIsPublicOrPrivate, cfpIsRestricted } from "../data/srm/srm.helpers";
import { useDataContext } from "../data/DataContext";
import { useRouter } from "next/router";
import { observer } from "mobx-react-lite";
import { replaceEventNameInText, stageValidate } from "../data/srm/forms.actions";
import { ErrorUnknown } from "../components/Errors/ErrorUnknown/ErrorUnknown";
import { useI18nLocaleFormat } from "../hooks/useI18nLocaleFormat";
import { getScope } from "../../environment";

export interface ConferenceCfpPageProps {
    cfpEvent: CfpEvent | null;
    locale: string;
}

const pushStageToRouter = (router, stage: number, dontSaveToHistory: boolean = false) => {
    const currentHash = router.asPath.split('#')[1];

    let slug: string;

    if (!stageValidate(stage)) {
        slug = "#step1";
    } else if (stage === 100) {
        slug = "#stepFinal";
    } else {
        slug = `#step${stage + 1}`;
    }

    if (currentHash !== undefined && `#${currentHash}` === slug) {
        return;
    }

    if (dontSaveToHistory) {
        router.replace(slug);
    } else {
        router.push(slug);
    }
}

const ConferenceCfpPage: FC<ConferenceCfpPageProps> = (props) => {
    const {
        cfpEvent
    } = props;

    // deps
    const { isInternalUser, isLogged } = useDataContext().stores.authStore;
    const { localeAs, localeFrom } = useI18nLocaleFormat();
    const router = useRouter();

    // states
    const [ step, setStep ] = useState<number>(undefined);

    // computed
    const hash = router.asPath.split('#')[1];

    // effects
    // router effect change hash
    useEffect(() => {
        const hashStep = getStageNumberFromHash(hash);
        if (hashStep === undefined) {
            pushStageToRouter(router, 0, true);
        } else {
            setStep(hashStep);
        }
    }, [ hash ]);

    // UI
    const renderContent = () => {
        if (isLogged === undefined) {
            return null;
        }

        if (getScope(undefined) === undefined) {
            return null;
        }

        if (!cfpEvent) {
            return (
                <ErrorUnknown>
                    <p>{localeAs("form.disabled.notFound")}</p>
                </ErrorUnknown>
            )
        }

        if (cfpIsClosed(cfpEvent)) {
            return (
                <ErrorUnknown cfpEvent={cfpEvent}>
                    <p>{localeAs("form.disabled.closed")}</p>
                </ErrorUnknown>
            )
        }

        if (cfpIsFuture(cfpEvent)) {
            return (
                <ErrorUnknown cfpEvent={cfpEvent}>
                    <p>{replaceEventNameInText(localeAs("form.disabled.future"), localeFrom(cfpEvent.event.name))}</p>
                </ErrorUnknown>
            )
        }

        if (cfpIsPublicOrPrivate(cfpEvent)) {
            return (
                <CallForPapersController step={step}
                                         cfpEvent={cfpEvent}
                                         pushStageToRouter={pushStageToRouter}
                                         scope={getScope(undefined)}
                />
            )
        }

        if (cfpIsRestricted(cfpEvent)) {
            if (isInternalUser) {
                return (
                    <CallForPapersController step={step}
                                             cfpEvent={cfpEvent}
                                             pushStageToRouter={pushStageToRouter}
                                             scope={getScope(undefined)}
                    />
                );
            }
            return (
                <ErrorUnknown cfpEvent={cfpEvent}>
                    <p>{replaceEventNameInText(localeAs("form.disabled.restricted"), localeFrom(cfpEvent.event.name))}</p>
                </ErrorUnknown>
            );
        }

        return (
            <ErrorUnknown cfpEvent={cfpEvent}>
                <p>{replaceEventNameInText(localeAs("form.disabled.oops"), localeFrom(cfpEvent.event.name))}</p>
            </ErrorUnknown>
        )
    }

    return (
        <Layout>
            {
                renderContent()
            }
        </Layout>
    )
}

export default observer(ConferenceCfpPage);

// noinspection JSUnusedGlobalSymbols
export const getServerSideProps: GetServerSideProps<ConferenceCfpPageProps> = async (context) => {
    const locale = context.locale || 'ru';

    const eventVersionInfoFromUrl = getEventVersionInfoFromUrl(context.params);
    let cfpEvent: CfpEvent | null = null;

    if (eventVersionInfoFromUrl !== undefined) {
        // check CFP are in correct event list and not permanent closed
        const srmApiService = new SrmApiService(true);

        cfpEvent = await srmApiService.getCfpEventByEventVersion(eventVersionInfoFromUrl);
    }

    return {
        props: {
            locale,
            cfpEvent
        }
    }
}
